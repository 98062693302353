<template>
 <SalesTargetWrap>
  <sdCards title="Tekenconversie">
   <div class="target-progressbar-wrap">
    <SVG
     :percent="Math.round(signConversion.signPercentage) < 2 ? 3 : Math.round(signConversion.signPercentage)"
     height="117.766"
     viewBox="0 0 225.324 117.766"
     width="225.324"
     xm-lns="http://www.w3.org/2000/svg"
    >
     <g id="Group_1657" data-name="Group 1657" transform="translate(-536.893 -818.88)">
      <text
       id="_70_"
       data-name="70%"
       font-family="Inter-Bold, Inter"
       font-size="30"
       font-weight="700"
       transform="translate(618 907)"
      >
       <tspan x="0" y="0"> {{ Math.round(signConversion.signPercentage) }}%</tspan>
      </text>
      <text
       id="Progress"
       fill="#868eae"
       font-family="Inter-Regular, Inter"
       font-size="15"
       transform="translate(619 931)"
      >
       <!--       <tspan x="0" y="0"> Conversie</tspan>-->
      </text>
      <path
       id="Path_1041"
       d="M253.5,399.119c.718-56.767,49.862-102.114,106.9-100.622,54.969,1.437,100.569,45.944,101.22,100.622"
       data-name="Path 1041"
       fill="none"
       stroke="#e8faf4"
       stroke-linecap="round"
       stroke-linejoin="round"
       stroke-width="17"
       transform="translate(292 528.92)"
      />
      <path
       id="Path_1042"
       d="M253.5,399.119c.718-56.767,49.862-102.114,106.9-100.622,54.969,1.437,100.569,45.944,101.22,100.622"
       data-name="Path 1042"
       fill="none"
       stroke="#20c997"
       stroke-linecap="round"
       stroke-linejoin="round"
       stroke-width="17"
       transform="translate(292 528.92)"
      />
     </g>
    </SVG>
   </div>
   <div class="s-target-list d-flex justify-content-between">
    <div class="s-target-list__item ">
     <h2>{{ kFormatter(signConversion.signCount) }}</h2>
     <p>Getekend</p>
    </div>
    <div class="s-target-list__item">
     <h2>{{ kFormatter(signConversion.totalCount) }}</h2>
     <p>Totaal</p>
    </div>
   </div>
  </sdCards>
 </SalesTargetWrap>
</template>
<script>
import {SalesTargetWrap} from '../../style';
import {defineComponent} from 'vue';
import VueTypes from "vue-types";
import Styled from "vue3-styled-components";
import {kFormatter} from "@/utility/utility";

const SVG = Styled('svg', ['percent'])`
    #Path_1042{
        stroke-dasharray: 312;
        stroke-dashoffset: ${({percent}) => (312 * (100 - percent)) / 100};
    }
`;

const SalesTarget = defineComponent({
 name: 'SalesTarget',
 components: {SalesTargetWrap, SVG},
 props: {
  signConversion: VueTypes.object.def({
   signCount: 0,
   signPercentage: 0,
   totalCount: 0,
  }),
 },
 methods: {
  kFormatter,
 }
});

export default SalesTarget;
</script>
